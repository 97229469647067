export default {
    nav: {
        data: {
            label: 'Stamgegevens'
        },
        masterdata: {
            truckingCompany: 'Transportbedrijf',
            label: 'Assets',
            users: 'Gebruikers',
            truck: 'Trucks',
            trailer: 'Trailers',
            globalValues: 'Settings',
            globalFiles: 'Bestanden',
            trip: 'Routes',
            customer: 'Klanten',
            invoice: 'Facturen',
            district: 'Districten',
            service: 'Service truck/trailer',
            emailTemplate: 'E-mail template',
            activityStatusEmailTemplate: 'Activiteit template',
            driver: 'Drivers',
            metabase: 'Metabase',
            tasks: 'Taken',
            goodsDescription: 'Goederen Omschrijving',
            costCalculation: 'Kosten berekening',
        },
    },
    general: {
        notAvailable: 'Niet beschikbaar',
        noExtraInformationAvailable: 'Geen extra informatie beschikbaar',
        at: 'at',
        viewLogs: 'Bekijk logs',
        error: 'Fout opgetreden',
        loading: 'Loading',
        canceled: 'Geannuleerd',
        loadTrips: 'Breaks laden',
        book: 'Boek!',
        na: 'n/a',
        somethingWentWrong: 'Er is iets fout gegaan.',
        exportInProgress: 'Het exporteren is gestart. Het kan even duren voordat een grote export voltooid is (limiet 10.000). Even geduld aub...',
    },
    geo: {
        notFound: 'Geen locatie gevonden!',
        notStarted: 'Geo Locatie niet gevonden',
    },
    enums: {
        weekday: {
            monday: 'Maandag',
            tuesday: 'Dinsdag',
            wednesday: 'Woensdag',
            thursday: 'Donderdag',
            friday: 'Vrijdag',
            saturday: 'Zaterdag',
            sunday: 'Zondag',
        },
        lengthUnit: {
            miles: 'Miles',
            milesShort: 'mi',
            kilometers: 'Kilometers',
            kilometersShort: 'km',
        },
        externalDataSource: {
            transics: 'Transics',
        },
        costCalculationVariation: {
            exchange_rate: 'Wisselkoers',
            europe_mainland: 'Europa vasteland',
            north_uk: 'Noord UK',
            south_uk: 'Zuid UK',
            ireland: 'Ierland',
        },
        correctionType: {
            daily_rate: 'Dagtarief',
            other_costs: 'Andere kosten',
        },
    },
    tooltips: {
        masquerade: 'Masquerade',
        edit: 'Bewerken',
        copy: 'Kopiëren',
        delete: 'Verwijderen',
        add: 'Toevoegen',
        view: 'Bekijken',
        restore: 'Herstellen',
    },
    form: {
        fileType: {
            pdf: 'PDF',
            image: 'IMG',
            xls: 'XLS',
            any: 'Anders',
            none: 'Geen bestand geüpload',
        },
        pdfLoading: 'Pdf laden...',
        pdfError: 'Fout laden pdf...',
        sendButton: 'Versturen',
        saveButton: 'Opslaan',
        saveCreateEmpty: 'Opslaan & Nieuw',
        saveAndBack: 'Opslaan & Terug',
        saveCreateKeepData: 'Opslaan & Kopiëren',
        previewSavePdf: 'Opslaan & Bekijk PDF',
        sendDraftSaveButton: 'Opslaan & Versturen',
        saveClose: 'Opslaan & Sluiten',
        close: 'Sluiten',
        cancel: 'Annuleren',
        continue: 'Doorgaan',
        repeat: 'Herhalen',
        multiPick: {
            searchPlaceholder: 'Selecteer ...',
            selectedText: '$1 of $2 geselecteerd',
            noneSelectedText: 'Niks geselecteerd',
            selectAllButton: 'Alles',
            selectNoneButton: 'Niks geselecteerd',
        },
        notifications: {
            saveSuccess: 'Succesvol opgeslagen',
            deleteSuccess: 'Succesvol verwijderd',
            saveError: 'Fout bij opslaan ({{status}})',
            saveValError: 'Niet alle gegevens zijn correct ingevuld',
            saveAuthError: 'Ongeldige inloggegevens',
            newAppVersion: 'Nieuwe versie beschikbaar, klik om de pagina te herladen',
        },
        submitButton: 'Indienen',
        cancelButton: 'Annuleren',
        addButton: 'Toevoegen',
        copyButton: 'Kopiëren',
        publishButton: 'Uitgeven',
        startDate: 'Start datum',
        endDate: 'Eind datum',
        deleteConfirmation: 'Weet u zeker dat u deze entiteit wilt verwijderen?',
        restoreConfirmation: 'Weet u zeker dat u deze entiteit wilt herstellen?',
        clearConfirmation: 'Weet u zeker dat u de gegevens van deze entiteit wilt wissen?',
        goBackConfirmation: 'Weet je zeker dat je terug wilt gaan zonder op te slaan?',
        search: 'Zoeken',
        actions: 'Acties',
        exportButton: 'Export'
    },
    trailer: {
        create: {
            title: 'Trailer aanmaken'
        },
        overview: {
            title: 'Trailers',
            addButton: 'Trailer',
        },
        edit: {
            title: 'Trailer bewerken: {{id}}',
            basicInfoTitle: 'Informatie',
            trailerSpecTitle: 'Trailer specs',
            apkTitle: 'TUV',
            activateTrailer: 'Trailer activeren',
            deactivateTrailer: 'Trailer deactiveren',
            cannotDeactivate: 'Kan niet deactiveren. Stuur de trailer eerst naar de werf',
            trailerDeactivated: 'De trailer is gedeactiveerd',
            locationRequired: 'Vul de benodigde werflocatie in',
            dropTruck: 'Ontkoppel de truck om de trailer te deactiveren',
            errorMessage: {
                fleetNumber: 'Het vlootnummer moet uniek zijn onder de actieve trailers.'
            }
        },
        field: {
            attachedTruck: {
                label: 'Gekoppelde trucks',
            },
            id: {
                label: 'ID',
            },
            licensePlate: {
                label: 'Kentekenplaat',
            },
            apkExpiryDate: {
                label: 'APK Vervaldatum'
            },
            apkExpiryDateFrom: {
                label: 'APK van'
            },
            apkLastExecutedDate: {
                label: 'APK laatste uitgevoerde datum'
            },
            apkExecutedDate: {
                label: 'APK uitgevoerd'
            },
            apkExpiryDateNew: {
                label: 'APK vervaldatum nieuw'
            },
            fleetNumber: {
                label: 'Vlootnummer'
            },
            fleetCode: {
                label: 'Vlootcode'
            },
            service: {
                label: 'Service'
            },
            tuv: {
                label: 'Tuv'
            },
            owner: {
                label: 'Eigenaar'
            },
            truckingCompany: {
                label: 'Bedrijf'
            },
            dataSource: {
                label: 'Data provider',
            },
            dataReference: {
                label: 'Transics key',
            },
            location: {
                label: 'Werf locatie',
            },
            source: {
                label: 'source'
            },
            equipment: {
                label: 'Uitrusting'
            },
            remarks: {
                label: 'Opmerking'
            },
            active: {
                label: 'Trailer actief / inactief',
                overviewColumn: 'Actief',
                options: {
                    all: 'Alles',
                    yes: 'Actief',
                    no: 'Inactief',
                }
            },
            assignedUser: {
                label: 'Toegewezen aan'
            },
            name: {
                label: 'Naam'
            },
            cooled: {
                label: 'Gekoeld / Niet gekoeld',
                overviewLabel: 'Gekoeld',
                options: {
                    all: 'Alles',
                    yes: 'Gekoeld',
                    no: 'Niet gekoeld',
                },
            },
            lift: {
                label: 'Lift / Geen lift',
                overviewLabel: 'Lift',
                options: {
                    all: 'Alles',
                    yes: 'Lift',
                    no: 'Geen lift',
                },
            },
        },
    },
    truck: {
        overview: {
            title: 'Trucks',
            addButton: 'Truck',
        },
        modal: {
            headerTitle: 'Vul de locatie in om de truck te activeren',
            headerTitleSplitTrip: 'Vul de locatie in van de werf',
            changeActivityType: 'Verander type activiteit naar werf drop',
            detachTrailerFromTruck: 'Ontkoppel trailer van truck',
            changeModalEta: 'ETA aanpassen',
        },
        edit: {
            title: 'Truck bewerken: {{id}}',
            basicInfoTitle: 'Informatie',
            permitInfoTitle: 'Vergunningsinformatie',
            identificationTitle: 'Truck identificatie',
            specsTitle: 'Truck specs',
            tuvTitle: 'Truck TUV',
            listServices: 'Services',
            activateTruck: {
                label: 'Activeer truck',
                success: 'Truck succesvol geactiveerd',
            },
            deactivateTruck: {
                label: 'Deactiveer truck',
                success: 'Truck succesvol gedeactiveerd',
            },
            messageActivate: 'DE truck is gedeactiveerd',
            locationTruck: 'Voeg de benodigde werf locatie toe',
            dropTrailer: 'Ontkoppel de trailer om de truck te deactiveren',
            errorMessage: {
                fleetNumber: 'Het vlootnummer moet uniek zijn onder de actieve trucks.'
            }
        },
        field: {
            id: {
                label: 'ID',
            },
            attachedTrailer: {
                label: 'Gekoppelde trailer',
            },
            fleetNumber: {
                label: 'Vlootnr.',
            },
            licensePlate: {
                label: 'Kenteken',
            },
            owner: {
                label: 'Eigenaar',
            },
            contract: {
                label: 'Contract',
            },
            truckingCompany: {
                label: 'Eigenaar bedrijf'
            },
            fuelCapacity: {
                label: 'Brandstof capaciteit'
            },
            telematica: {
                label: 'Data Provider',
            },
            dataSource: {
                label: 'Data provider',
            },
            dataReference: {
                label: 'Key',
            },
            phone: {
                label: 'Telefoon',
            },
            location: {
                label: 'Werf locatie',
            },
            terminal: {
                label: 'Ondersteunde terminals',
            },
            color: {
                label: 'Kleur'
            },
            type: {
                label: 'Type',
                value: {
                    dallessi: 'Dalessi',
                    TIP: 'TIP'
                }
            },
            registrationCountry: {
                label: 'Registratie land'
            },
            brandType: {
                label: 'Merk'
            },
            emailHaulier: {
                label: 'E-mail vervoerder'
            },
            service: {
                label: 'Service'
            },
            weight: {
                label: 'Gewicht'
            },
            registrationDate: {
                label: 'Registratie datum'
            },
            fixedEquipment: {
                label: 'Vaste uitrusting'
            },
            remarks: {
                label: 'Opmerking'
            },
            entity: {
                label: 'Entiteit'
            },
            active: {
                label: 'Truck actief / inactief',
                overviewColumn: 'Actief',
                options: {
                    all: 'Alles',
                    yes: 'Actief',
                    no: 'Inactief',
                }
            },
            assignedUser: {
                label: 'Toegewezen aan'
            },
            currentLocation: {
                label: 'Huidige locatie'
            },
            currentActivity: {
                label: 'Huidige activiteit'
            },
            manualEta: {
                label: 'Handmatige ETA'
            },
            weekendPause: {
                overviewColumn: 'Weekendpauze',
            },
            mileage: {
                label: 'Kilometerstand'
            },
            nextApk: {
                label: 'Volgende APK'
            },
            nextTacho: {
                label: 'Volgende Tacho'
            },
            permit: {
                label: 'Vergunning',
            },
            permitExpirationDate: {
                label: 'Vergunning vervaldatum'
            },
            waPermit: {
                label: 'WA vergunning'
            },
            wabco: {
                label: 'Wabco',
                options: {
                    all: 'Alles',
                    yes: 'Jaa',
                    no: 'Nee',
                }
            },
            soldAt: {
                label: 'Verkocht op',
            }
        },
        create: {
            title: 'Truck aanmaken'
        },
    },
    tuv: {
        overview: {
            addButton: 'TUV',
        },
        field: {
            tuvExecutedDate: {
                label: 'TUV-uitvoeringsdatum'
            },
            tuvExpiryDate: {
                label: 'TUV-vervaldatum'
            },
        }
    },
    driver: {
        overview: {
            title: 'Drivers',
            editButton: 'Driver bewerken',
            addButton: 'Driver',
            addRouteButton: 'Break toevoegen',
            popup:{
                info: 'Eindigt / begint in minder dan 14 dagen \n Resterend aantal dagen: {{n}} \n Rood - bestuurder gaat weg \n Blauw - bestuurder komt terug',
            }
        },
        create: {
            title: 'Driver aanmaken',
        },
        edit: {
            title: 'Driver bewerken',
        },
        field: {
            header: {
                label: 'Driver'
            },
            id: {
                label: 'ID'
            },
            name: {
                label: 'Naam',
                placeholder: '...',
            },
            firstName: {
                label: 'Voornaam',
                placeholder: '...',
            },
            lastName: {
                label: 'Achternaam',
                placeholder: '...',
            },
            phoneNumber: {
                label: 'Telefoon nr.',
                placeholder: '...',
            },
            dataReference: {
                label: 'Key',
                placeholder: 'Key',
            },
            addDriver: {
                label: 'Driver toevoegen'
            },
            selectDriver: {
                label: 'Selecteer driver'
            },
            truck: {
                label: 'Truck',
                licensePlate: {
                    label: 'Truck kenteken'
                },
                fleetNumber: {
                    label: 'Truck vlootnr.'
                },
            },
            weekendPause: {
                label: 'Weekendpauze',
                options: {
                    '24': '24h',
                    '45': '45h',
                    stopTruck: 'Stop truck',
                    home: 'Naar huis'
                },
                placeholder: 'Onbekend',
                shorterLabels: {
                    stopTruck: 'ST',
                    home: 'H'
                }
            },

        }
    },
    goodsDescription: {
        overview: {
            title: 'Goederenbeschrijvingen',
        },
        create: {
            title: 'Goederenbeschrijving aanmaken',
        },
        edit: {
            title: 'Goederenbeschrijving bewerken',
        },
        field: {
            id: {
                label: 'ID'
            },
            name: {
                label: 'Beschrijving',
                placeholder: '...',
            },
            usageCount: {
                label: 'Gebruiksfrequentie',
                placeholder: '...',
            }
        }
    },
    service: {
        overview: {
            title: 'Servicelijst voor trucks en trailers',
            addButton: 'Service',
            showMore: 'Toon meer',
            markAsDone: 'Markeer als klaar',
        },
        create: {
            title: 'Service aanmaken',
        },
        edit: {
            title: 'Bewerk service: {{id}}',
            chooseTruck: 'Selecteer truck',
            chooseTrailer: 'Selecteer trailer',
            errorMessage: 'Kan geen service toewijzen aan zowel de truck als de trailer',
        },
        delete: {
            title: 'Service verwijderen',
        },
        types: {
            repair: 'reparatie',
            service: 'service',
            maintenance: 'onderhoud',
            other: 'anders',
        },
        field: {
            id: {
                label: 'ID',
            },
            type: {
                label: 'Type'
            },
            location: {
                label: 'Locatie'
            },
            visitDate: {
                label: 'Bezoekdatum'
            },
            visitTime: {
                label: 'Tijd van bezoek'
            },
            subject: {
                label: 'Onderwerp'
            },
            estimatedDueTime: {
                label: 'Geschatte tijd'
            },
            explanation: {
                label: 'Rich text explanation'
            },
            truck: {
                label: 'Truck fleet number'
            },
            trailer: {
                label: 'Trailer vlootnr.'
            },
            status: {
                label: 'Status'
            },
            markedAsDone: {
                label: 'Gemarkeerd als klaar'
            }
        }
    },
    route: {
        overview: {
            title: 'Break',
            addButton: 'Break toevoegen',
            editButton: 'Break bewerken',
            copyButton: 'Break kopiëren',
        },
        create: {
            title: 'Break aanmaken',
        },
        view: {
            title: 'Break bekijken',
        },
        delete: {
            title: 'Break verwijderen',
        },
        edit: {
            title: 'Break bewerken'
        },
        field: {
            name: {
                label: 'Naam',
            },
            code: {
                label: 'Integratie code',
            },
            transporter: {
                label: 'Terminaltransporteur',
            },
            fromTerminal: {
                label: 'Vertrekterminal',
            },
            toTerminal: {
                label: 'Aankomstterminal',
            },
            invoiceWay: {
                label: 'Factuur manier',
            },
            deactivateModal: {
                description: 'Voer de datum in vanaf wanneer deze schedule en de vertrektijden moeten worden gedeactiveerd.'
            },
            validFrom: {
                label: 'Geldig vanaf'
            },
            validTo: {
                label: 'Geldig tot'
            }
        },
        planning: {
            iconLabel: 'Break',
            in14DaysLabel: 'Na 14 dagen ',
            after14DaysLabel: 'In 14 dagen '
        }
    },
    locationEditFreeForm: {
        label: 'Locatie',
        placeholder: 'Kies een locatie',
        showLocationText: 'Locatie weergeven'
    },
    location: {
        field: {
            city: {
                label: 'Stad',
            },
            code: {
                label: 'Code',
            },
            companyName: {
                label: 'Bedrijf',
            },
            address: {
                label: 'Adress',
            },
        },
    },
    truckingCompany: {
        overview: {
            title: 'Transportbedrijfen',
            addButton: 'Transportbedrijf',
            truckCount: 'Truck count',
        },
        create: {
            title: 'Transportbedrijf aanmaken',
        },
        edit: {
            title: 'Transportbedrijf - {{name}}',
            addPurchaseInvoiceButtonTooltip: 'Aankoopfactuur toevoegen',
        },
        trucks: {
            add: 'Truck toevoegen',
        },
        trailer: {
            add: 'Trailer toevoegen',
        },
        contract: {
            add: 'Contract toevoegen',
        },
        tab: {
            trucks: 'Trucks',
            trailers: 'Trailers',
            contracts: 'Contracts',
        },
        field: {
            name: {
                label: 'Naam',
                placeholder: 'Vul een naam in ...',
            },
            creditorNumber: {
                label: 'Crediteur nummer',
                placeholder: 'Vul een crediteurnummer in',
            },
            colorCompany: {
                label: 'Klant kleur',
                placeholder: 'Kies kleur',
            },
            emailAddress: {
                label: 'E-mailadres',
                placeholder: 'E-mailadres ...',
            },
            phone: {
                label: 'telefoonnummer',
                placeholder: 'Telefoonnummer ...',
            },
            location: {
                label: 'Landcode',
            },
            iban: {
                label: 'IBAN',
                placeholder: 'Vul een IBAN-nummer in ...',
            },
            swiftBic: {
                label: 'SWIFT / BIC code',
                placeholder: '',
            },
            chamberOfCommerce: {
                label: 'KVK',
                placeholder: '',
            },
            vatCode: {
                label: 'BTW nr.',
                placeholder: '',
            },
            specialization: {
                label: 'Specialisatie',
            },
            paymentTerms: {
                label: 'Betalingsvoorwaarden (in dagen)',
            },
            blacklisted: {
                label: 'Zwartelijst',
                options: {
                    all: 'Alles',
                    blacklisted: 'Ja',
                    notBlacklisted: 'Nee',
                }
            },
            blacklistedReason: {
                label: 'Zwartelijst reden',
            },
            contact: {
                label: 'Contact',
                placeholder: 'Selecteer contacttype ...',
                value: {
                    dayshift: 'Dagdienst',
                    invoice: 'Factuur',
                    nightshift: 'Nachtdienst',
                    administration: 'Administratie',
                    general: 'Algemeen',
                    operation: 'Operatie'
                }
            },
            signedWageAgreement: {
                label: 'Ondertekend min. loonovereenkomst',
            },
            signedAgreementExpiryDate: {
                label: 'Vervaldatum ondertekende overeenkomst',
            },

            liabilityInsurance: {
                label: 'Aansprakelijkheid en verzekering',
            },
            liabilityInsuranceExpiryDate: {
                label: 'Einddatum aansprakelijkheidsverzekering',
            },
            letterheadExpiryDate: {
                label: 'Vervaldatum briefhoofd'
            },
            euLicenseForGoodsTransportationExpiryDate: {
                label: 'Vervaldatum EU-licentie'
            },
            cmrInsurancePolicyExpiryDate: {
                label: 'Einddatum CMR-verzekeringspolis'
            },
            licenses: {
                label: 'Licenties',
            },
            letterhead: {
                label: 'Briefhoofd',
            },
            eu_license_for_goods_transportation: {
                label: 'EU-vergunning voor goederenvervoer',
            },
            cmr_insurance_policy: {
                label: 'CMR-verzekering',
            },
            licensesExpiryDate: {
                label: 'Licentie vervaldatum',
            },
            subcontractor: {
                label: 'Onderaannemer',
            },
            priority: {
                label: 'Prioriteit',
                instruction: 'Bij een hogere bedrijfsprioriteit worden het bedrijf en de activa ervan hoger in de lijsten weergegeven',
            },
            owner: {
                label: 'Eigenaar',
                options: {
                    all: 'Alles',
                    own: 'Eigen',
                    subcontractors: 'Aannemers',

                }
            }
        },
    },
    district: {
        overview: {
            title: 'Wijk',
        },
        field: {
            id: {
                label: 'ID',
            },
            name: {
                label: 'Naam gebied',
            },
            area: {
                label: 'Polygon',
            },
        }
    },
};
