import React, { Component } from 'react';
import { List, Image, Checkbox } from 'semantic-ui-react';
import { DriverActionDocument } from '../../store/DriverActionDocument';
import { DriverAction } from 'react-logistics-driverapp/src/store/DriverAction';
import styled from 'styled-components';

const Description = styled.span`
    color: var(--cy-gray-500);
    white-space: pre-line;
    font-style: italic;
`;

export type DriverActionComponentProps = {
    action: DriverAction,
    documentSelected: (document: DriverActionDocument) => void,
}


export class DriverActionComponent extends Component<DriverActionComponentProps> {
    _renderActionImages = (action) => {
        const { documentSelected } = this.props;

        return (
            <List horizontal style={{
                display: 'flex',
                overflowX: 'scroll'
            }}>
                {action.documents && action.documents.map((document) => {
                    return (
                        <List.Item>
                            <Image centered
                                onClick={() => documentSelected?.(document)}
                                src={document.file}
                                height='64px'
                                width='64px'
                            />
                        </List.Item>
                    );
                })}
            </List>
        );
    }

    render() {
        const { action } = this.props;

        return (
            <div data-test-activity-action>
                {action.label && <div><b>{action.label}</b></div>}
                {action.description && <Description>{action.description}</Description>}
                {action.type === 'text' && action.value && <div>{action.value}</div>}
                {action.type === 'numeric' && action.value && <div>{action.value}</div>}
                {action.type === 'check' && <div><Checkbox readOnly checked={action.checked} /></div>}
                {action.type === 'image' && this._renderActionImages(action)}
            </div>
        );
    }
}