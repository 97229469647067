import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, TopMenu, MenuRow, NavMenu, NavItem, Logo } from 're-cy-cle';
import { Header, Modal, Icon } from 'semantic-ui-react';
import { Route, withRouter } from 'react-router-dom';
import ImgLogo from 'image/ag/logo.svg';
import { SmallAvatar } from 'component/UserAvatar';
import { BUILD_INFO } from 'helpers';
import { t } from '@code-yellow/spider';
import { isDriverUser } from 'helpers/currentUser';

import '@code-yellow/spider'

import { renderReportingMenu, renderReportingSubMenu } from '@code-yellow/core-metabase';
import { renderStatusCeleryMonitor, renderCeleryMonitorMenu, renderCeleryMonitorSubMenu } from '@code-yellow/core-monitoring';
import { renderFinanceMenu, renderFinanceSubMenu } from 'react-logistics-finance/src';
import { renderAdministrationMenu, renderAdministrationSubMenu } from 'react-logistics-administration/src/index'; // [TODO] export to module
import { renderMasterDataMenu, renderMasterDataSubMenu } from 'react-logistics-masterdata/src/index'; // [TODO] export to module '@code-yellow/logistics-masterdata'
import { renderPlanningMenu, renderPlanningSubMenu } from 'react-logistics-planning/src/index'; // [TODO] export to module '@code-yellow/logistics-planning'
import { renderCommunicationMenu, renderCommunicationSubMenu } from 'react-core-communication/src/index';
import { renderDriverAppMenu, renderDriverAppSubMenu } from 'react-logistics-driverapp/src/index';
const MyLogo = () => (
    <Logo>
        <img src={ImgLogo} height="35" alt="logo"/>
    </Logo>
);
@withRouter
@observer
export default class AppHeader extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        // moduleRepository: PropTypes.instanceOf(ModuleRepository).isRequired,
    };

    @observable debug = false;
    @observable showLogoutModal = false;

    toggleDebug = () => {
        this.debug = !this.debug;

        if (this.debug) {
            localStorage.setItem('debug', true);
        } else {
            localStorage.removeItem('debug');
        }
    }

    constructor(...args) {
        super(...args);
        this.debug = !!localStorage.getItem('debug');
    }

    hasPermission = perms => {
        return this.props.store.currentUser.hasPermission(perms);
    };

    renderAccountMenu = () => {
        const { store } = this.props;
        const { version, branch } = BUILD_INFO;

        return (
            <NavItem
                title={
                    <span>
                        <SmallAvatar user={store.currentUser}/>
                        {' '}
                        {' '}
                        {store.currentUser.fullName} (
                        {branch && branch !== 'production' && branch + ' '}
                        {version}
                        )
                    </span>
                }
                to="/account/details"
                activePath="/account/"
            />
        );
    };

    renderAccount = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.account.account')}
                    to="/account/details"
                />
                <NavItem
                    title={t('nav.account.users')}
                    to="/account/user/overview"
                    activePath="/account/user/"
                />
                <NavItem
                    title={t('nav.account.globalFiles')}
                    to="/account/global-file/overview"
                    activePath="/account/global-file/"
                />
                <NavItem
                    title={t('nav.account.accessLog')}
                    to="/account/access-log/overview"
                    activePath="/account/access-log/"
                />
                <NavItem
                    title={t('nav.account.globalValues')}
                    to="/account/global-value/overview"
                    activePath="/account/global-value/"
                />
                <NavItem title={t('nav.account.changelog')} to="/account/changelog"/>
            </NavMenu>
        );
    };

    render() {
        const { currentUser } = this.props.store;

        if (!this.props.store.isAuthenticated || (
            currentUser.groupNames === undefined &&
            !currentUser.isSuperuser
        )) {
            return (
                <TopMenu>
                    <MenuRow style={{ borderBottom: '4px solid var(--indigo-800)' }}>
                        <MyLogo/>
                    </MenuRow>
                </TopMenu>
            );
        }

        const logoutModal = (
            <Modal closeIcon open={this.showLogoutModal} onClose={() => this.showLogoutModal = false} basic
                   size='small'>
                <Header icon='archive' content={t('user.account.logoutButton')}/>
                <Modal.Content>
                    <p>
                        {t('user.account.logout.confirm')}
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color='red' inverted onClick={() => this.showLogoutModal = false}>
                        <Icon name='remove'/> {t('form.no')}
                    </Button>
                    <Button color='green' inverted onClick={() => {
                        this.props.store.performLogout().then(() => {
                            this.showLogoutModal = false;
                        });
                    }}>
                        <Icon name='checkmark'/> {t('form.yes')}
                    </Button>
                </Modal.Actions>
            </Modal>
        );

        let menu = (<></>);

        if (!isDriverUser()) {
            menu =
                <TopMenu>
                    <MenuRow >
                        <MyLogo/>
                        <NavMenu>
                            {renderAdministrationMenu()}
                            {renderPlanningMenu()}
                            {renderFinanceMenu()}
                            {renderMasterDataMenu()}
                            {renderCeleryMonitorMenu(currentUser)}
                            {renderCommunicationMenu()}
                            {renderReportingMenu()}
                            {renderDriverAppMenu()}
                            <NavItem
                                title={renderStatusCeleryMonitor(currentUser)}
                                to="/celery-monitor/tasks-monitor/overview?limit=25&currentPage=1&order_by=name&.status_information:in=warning,error"
                                activePath="."
                            />
                        </NavMenu>
                        {this.renderAccountMenu()}
                    </MenuRow>
                    <MenuRow>
                        <Route path="/account" render={this.renderAccount} />
                        <Route path="/administration/" render={() => renderAdministrationSubMenu(currentUser)} />
                        <Route path="/reporting/" render={() => renderReportingSubMenu(currentUser)} />
                        <Route path="/celery-monitor/" render={() => renderCeleryMonitorSubMenu(currentUser)} />
                        <Route path="/master-data/" render={() => renderMasterDataSubMenu()} />
                        <Route path="/finance/" render={() => renderFinanceSubMenu()} />
                        <Route path="/communication/" render={() => renderCommunicationSubMenu()} />
                        <Route path="/planning/" render={() => renderPlanningSubMenu()} />
                        <Route path="/driver-app/" render={() => renderDriverAppSubMenu()} />
                    </MenuRow>
                </TopMenu>
        }

        return (
            <React.Fragment>
                {menu}
                {logoutModal}
            </React.Fragment>
        );
    }
}
